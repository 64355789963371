<template>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="旧密码:" prop="oldPass">
        <el-input clear="el-input" type="password" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="密码:" prop="pass">
        <el-input clear="el-input" type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="确认密码:" prop="checkPass">
        <el-input clear="el-input" type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
    </el-form>
</template>
<script>
import {updateUserPassword} from '../../service/getData';
export default {
  name: 'UserMessage',
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm() {
      if (this.ruleForm.pass === this.ruleForm.checkPass){
        if (this.ruleForm.oldPass===''){
          this.$message({
            type: 'error',
            message: '请输入旧密码'
          });
        } else {
          updateUserPassword(this.ruleForm.checkPass, this.ruleForm.oldPass).then(values=>{
            this.resetForm('ruleForm');
            let type = values.code===200?'success':'error';
            this.$message({
              type,
              message: values.message
            });
            this.$store.dispatch('removeMessage');
            let _this = this;
            setTimeout(function(){
              _this.$router.push({
                name: 'Login'
              });
            }, 1000);
          });
        }
      } else {
        this.$message({
          type: 'error',
          message: '两次密码不相同'
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-input {
  width: 300px;
}
</style>